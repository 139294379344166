// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import { Store } from 'Store'
import Signature from './Signature'

import styles from './styles.module.scss'

import transDomain from './translations/index.translations'


function MessageIncentive (props) {
    const { state } = React.useContext(Store)
    const { t } = props

    return (
        <div className={styles.message}>
            <div className={styles.messageTitle}>{t('message_title', {
                position: state.campaign.contactInfo.position
            })}</div>

            <p className={styles.messageParagraph}>{t('message_paragraph')}</p>

            <Signature />

            <div className={styles.messageFooter}>
                <strong>{state.campaign.contactInfo.name}</strong>, {state.campaign.contactInfo.position}, {state.campaign.client.name}
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(MessageIncentive)
