// @flow

const VIDEOS = {
    ford: 'https://s3.amazonaws.com/com.shift-marketing.videos.demo/ford-employee-pricing-2020-1/ford.mp4'
}

export function makeCampaign (style: string) {
    return {
        videos: {
            sidebar: VIDEOS[style] || VIDEOS.base
        }
    }
}
