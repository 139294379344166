
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns3959f70c6c2e07553b6107a017703fb2en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '3959f70c6c2e07553b6107a017703fb2', flattenObject(ns3959f70c6c2e07553b6107a017703fb2en_US), true, true);
        
            import ns3959f70c6c2e07553b6107a017703fb2en from './en.yaml'
            i18n.addResourceBundle('en', '3959f70c6c2e07553b6107a017703fb2', flattenObject(ns3959f70c6c2e07553b6107a017703fb2en), true, true);
        
        export default '3959f70c6c2e07553b6107a017703fb2';
    