// @flow
import queryString from 'query-string'
import merge from 'deepmerge'
import URL from 'url-parse'
import { Client, FetchClient, Lead, MockClient } from '@shift-marketing/shift-one-api-client'
import { makeCampaign } from './mock-client-options'

const query = queryString.parse(window.location.search)

function makeClient (baseUri: ?string): Client {
    if (typeof query['demo'] !== 'undefined') {
        let options = { makeCampaign }

        if (query['demo']) {
            try {
                const { campaign, ...otherOptions } = JSON.parse(query['demo'])

                if (campaign) { // If we have campaign override coming from the URL, we replace the makeCampaign option
                    options.makeCampaign = function (style: string) {
                        return merge(
                            makeCampaign(style),
                            campaign
                        )
                    }

                    options = merge(options, otherOptions)
                }
            } catch (error) {
                throw new Error('Unable to parse JSON')
            }
        }
        return new MockClient('automotive', options)
    }

    return new FetchClient(baseUri)
}

const APIClient = makeClient(process.env.REACT_APP_SHIFT_API_BASE_URL)

let entrypoint = window.sessionStorage.getItem('entrypoint')
if (!entrypoint || (new URL(entrypoint)).pathname.split('/')[1] !== window.location.pathname.split('/')[1]) {
    entrypoint = query._impersonate
        ? `${window.location.protocol}//${query._impersonate}`
        : window.location.href

    window.sessionStorage.setItem('entrypoint', entrypoint)
}
const campaignUrl = new URL(entrypoint)

export const fetchCampaign = (dispatch: any => void) => {
    (async function () {
        try {
            const campaign = await APIClient.getCampaign(campaignUrl)

            campaign.expiryDate = new Date(campaign.clientExpiryDate)

            dispatch({
                type: 'SET_CAMPAIGN',
                payload: campaign
            })

            dispatch({
                type: 'SET_LOCALE',
                payload: campaign.locales.default
            })
        } catch (error) {
            if (error.name === 'CampaignNotFoundError') {
                dispatch({ type: 'CAMPAIGN_NOT_FOUND' })
            }
        }
    })()
}

export const fetchLead = async (dispatch: any => void, code: string) => {
    const lead = await APIClient.getLead(campaignUrl, code)

    dispatch({
        type: 'SET_LEAD',
        payload: lead
    })

    dispatch({
        type: 'SET_CODE',
        payload: code
    })
}

export async function updateLead (dispatch: any => void, code: ?string, lead: Lead) {
    const updatedLead = await APIClient.updateLead(campaignUrl, code, lead)

    dispatch({
        type: 'SET_LEAD',
        payload: updatedLead
    })

    if (lead.isComplete) {
        dispatch({ type: 'MARK_AUTORESPONDER_AS_PENDING' })
    }
}

export async function patchLead (dispatch: any => void, code: ?string, fields: any, isComplete: boolean) {
    const lead = await APIClient.patchLead(campaignUrl, code, fields, isComplete)

    dispatch({
        type: 'SET_LEAD',
        payload: lead
    })

    if (isComplete) {
        dispatch({ type: 'MARK_AUTORESPONDER_AS_PENDING' })
    }
}

export function deleteLead (dispatch: any => void) {
    dispatch({
        type: 'SET_LEAD',
        payload: null
    })
}

export async function sendAutoresponder (dispatch: any => void, voucherName: string, voucher: File) {
    dispatch({
        type: 'MARK_AUTORESPONDER_AS_SENT',
        payload: null
    })

    APIClient.sendAutoresponder(campaignUrl, { [voucherName]: voucher })
}

export async function sendAppointmentDate (date: Date) {
    APIClient.sendAppointmentDate(campaignUrl, date)
}

export async function sendClientMessage (message: string) {
    APIClient.sendClientMessage(campaignUrl, message)
}
