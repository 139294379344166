// @flow
import * as React from 'react'

import { withTranslation } from 'react-i18next'
import withTheme from 'hoc/withTheme'

import styles from './styles.module.scss'
import supportedThemes from './themes/__supportedThemes.js'

import transDomain from './translations/index.translations'

import { Store } from 'Store'
import { wrapText } from '../Form/Helpers'

type ImagePath = string;

type Props = {
    theme: {
        background: string,
        color: string,
        logo: ImagePath,
    }
}

function Footer (props: Props) {
    const { state } = React.useContext(Store)
    const { theme } = props

    const terms = wrapText(
        state.campaign.incentives.voucher.terms.replace('[dealership_name]', state.campaign.client.name),
        180
    )

    const clientAddress = React.useMemo(() => {
        return state.campaign.client.address ? [
            [state.campaign.client.address.line1, state.campaign.client.address.line2],
            state.campaign.client.address.city,
            [state.campaign.client.address.province, state.campaign.client.address.postalCode]
        ].filter(Boolean).map(i => Array.isArray(i) ? i.filter(Boolean).join(' ') : i).join(', ') : null
    }, [state.campaign.client.address])

    return (
        <div className={styles.container} style={{ background: theme.background, color: theme.color }}>
            <img className={styles.logo} src={theme.logo} alt='' />

            <div className={styles.innerContainer}>
                <p className={styles.dealershipInfo}>
                    {clientAddress && `${state.campaign.client.name} | ${clientAddress}`}
                </p>

                <p className={styles.disclaimer}>{terms}</p>
            </div>
        </div>
    )
}

export default withTranslation(transDomain)(withTheme(supportedThemes)(Footer))
