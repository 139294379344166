// @flow
import * as React from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

import Toyota from './logo/Toyota'
import Ford from './logo/Ford'

function MainLogo (props) {
    return (
        <div className={cn(styles.container, styles.logo, props.className)} style={props.style}>
            {props.theme.isFord && <Ford color={props.eventColor || props.theme.eventColor}/>}
            {props.theme.isToyota && <Toyota color={props.eventColor || props.theme.eventColor} />}
        </div>
    )
}

export default withTheme(themes)(MainLogo)
