
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nse689eaecbe76af4a771114b0099f9719en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'e689eaecbe76af4a771114b0099f9719', flattenObject(nse689eaecbe76af4a771114b0099f9719en_US), true, true);
        
            import nse689eaecbe76af4a771114b0099f9719en from './en.yaml'
            i18n.addResourceBundle('en', 'e689eaecbe76af4a771114b0099f9719', flattenObject(nse689eaecbe76af4a771114b0099f9719en), true, true);
        
        export default 'e689eaecbe76af4a771114b0099f9719';
    